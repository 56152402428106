import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Common/Pages/Layout";
import Login from "./components/Login/Login";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import CacheBuster from "react-cache-buster";
import NotFound from "./components/Common/Pages/404";
import { setRoles } from "./redux/actions";
import { jwtDecode } from "jwt-decode"; // Corrected import
const packageJson = require("../package.json");
const version = packageJson.version;

export default function App() {
  const isProduction = process.env.NODE_ENV === "production";
  const [token, setToken] = useState(null);
  const [rolesUser, setRolesUser] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      try {
        const decodedToken = jwtDecode(tokenString);
        setToken(decodedToken);

        if (decodedToken.role) {
          setRolesUser(Array.isArray(decodedToken.role) ? decodedToken.role : [decodedToken.role]); // Ensure roles are an array
          console.log(decodedToken)
          store.dispatch(setRoles(Array.isArray(decodedToken.role) ? decodedToken.role : [decodedToken.role]));
        }
      } catch (e) {
        console.error("Error parsing token from localStorage", e);
      }
    }
    setLoading(false); // Set loading to false once done
  }, []);

  const isAuthorized = (requiredRole) => {
    if (!requiredRole) return true; // No role required
    return rolesUser.some((role) => requiredRole.includes(role)); // Ensure roles are properly checked
  };

  // If loading state is active, show a spinner or placeholder
  if (loading) {
    return <div>Loading...</div>; // Replace with a spinner or placeholder component if desired
  }

  return (
    <Suspense fallback={<div>Loading app...</div>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} // Cache busting enabled in production
            isVerboseMode={false}
            metaFileDirectory={"."}
          >
            {token && token.exp*1000 > Date.now() ? ( // Corrected token expiration check
              <Layout>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, requiredRole, ...rest } = route;
                    return (
                      <Route
                        key={index}
                        {...rest}
                        element={isAuthorized(requiredRole) ? element : <NotFound />}
                      />
                    );
                  })}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
            ) : (
              <Login />
            )}
          </CacheBuster>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
