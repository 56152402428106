import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import store from "../../../redux/store";
import { login, setLang } from "../../../redux/actions";
import { useSelector } from "react-redux";
import logo from "../../../resources/logo-cora_en.png";

function MenuBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null); // State for the submenu
  const [anchorElCasserole, setAnchorElCasserole] = useState(null);
  const [anchorElReport, setAnchorElReport] = useState(null);
  const [anchorElArticle, setAnchorElArticle] = useState(null);
  const [anchorElLangage, setAnchorElLangage] = useState(null);
  const [anchorElSurvey, setAnchorElSurvey] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { t, i18n } = useTranslation(["Menu"]);
  const userState = useSelector((state) => state.user);

  const pages = [
    {
      name: t("UserManagement"),
      url: "/UserManagement",
      requiredRole: ["AdminTI, Manager, Franchises, InternalEmployee"],
    },
  ];

  const pagesMobile = [
    { name: t("Memo Hebdo"), url: "/Articles", requiredRole: null },
    {
      name: t("WriteArticle"),
      url: "/WriteArticle",
      requiredRole: ["ArticleWriter, ArticleAdmin, AdminTI"],
    },
    {
      name: t("UserManagement"),
      url: "/UserManagement",
      requiredRole: ["AdminTI, Manager, Franchises, InternalEmployee"],
    },
    {
      name: t("Location"),
      url: "/Location",
      requiredRole: ["AdminTI, LocationAdmin, LocationReader"],
    },
    {
      name: t("CompanyManagement"),
      url: "/CompanyManagement",
      requiredRole: ["CompanyReader, CompanyAdmin, AdminTI"],
    },
    {
      name: t("Reports"),
      url: "/Reports",
      requiredRole: ["AdminTI, ReportAdmin, ReportWriter"],
    },
    {
      name: t("Dashboard"),
      url: "/Dashboard",
      requiredRole: ["AdminTI, ReportAdmin, ReportWriter"],
    },
    {
      name: t("SpecialMessage"),
      url: "/SpecialMessage",
      requiredRole: ["AdminTI, CaseroleAdmin"],
    },
    {
      name: t("Complaint"),
      url: "/Complaint",
      requiredRole: [
        "AdminTI, Manager, Franchises, KitchenManager, CaseroleAdmin, CasseroleClerk",
      ],
    },
    {
      name: t("CompetitionSurveyFranchisees"),
      url: "/CompetitionSurveyFranchisees",
      requiredRole: ["AdminTI, Franchises"],
    },
    {
      name: t("CompetitionSurveyInternalEmployees"),
      url: "/CompetitionSurveyInternalEmployees",
      requiredRole: ["AdminTI, InternalEmployee"],
    },
  ];

  const settings = [
    {
      name: t("Profil"),
      action: () => {
        navigate("/Profil");
      },
    },
    {
      name: t("Langage"), // Language switcher option
      action: (event) => setAnchorElLangage(event.currentTarget), // Opens the language sub-menu
      subMenu: [
        {
          name: t("FR"),
          action: () => {
            i18n.changeLanguage("FR");
            store.dispatch(setLang("FR"));
            setAnchorElLangage(null);
          },
        },
        {
          name: t("EN"),
          action: () => {
            i18n.changeLanguage("EN");
            store.dispatch(setLang("EN"));
            setAnchorElLangage(null);
          },
        },
      ],
    },
    {
      name: t("LoginAs"),
      action: (navigate) => {
        handleLoginAs();
      },
    },
    {
      name: "Logout",
      action: (navigate) => {
        localStorage.removeItem("token");
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      },
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setAnchorElLangage(null); // Close the language menu if it's open
  };

  const handleMenuAction = (action) => {
    handleCloseUserMenu();
    if (action) action(navigate);
  };

  const handleOpenArticleMenu = (event) => {
    setAnchorElArticle(event.currentTarget); // Opens the submenu
  };

  const handleCloseArticleMenu = () => {
    setAnchorElArticle(null); // Closes the submenu
  };

  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget); // Opens the submenu
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null); // Closes the submenu
  };

  const handleOpenCasseroleMenu = (event) => {
    setAnchorElCasserole(event.currentTarget); // Opens the submenu
  };

  const handleCloseCasseroleMenu = () => {
    setAnchorElCasserole(null); // Closes the submenu
  };

  const handleOpenReportMenu = (event) => {
    setAnchorElReport(event.currentTarget); // Opens the submenu
  };

  const handleCloseReportMenu = () => {
    setAnchorElReport(null); // Closes the submenu
  };

  const handleOpenLangageMenu = (event) => {
    setAnchorElLangage(event.currentTarget);
  };

  const handleOpenSurveyMenu = (event) => {
    setAnchorElSurvey(event.currentTarget);
  };
  const handleCloseSurveyMenu = () => {
    setAnchorElSurvey(null); // Closes the submenu
  };

  const handleLoginAs = async () => {
    const username = prompt("Please enter a valid username:", "");
    if (username) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Login/loginAs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify({ username, password: null }),
        }
      );
      const data = await response.json();
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("username", username);
      localStorage.setItem("token", data.token);
      store.dispatch(login({ username, idSession: data.idSession }));
      window.location.reload(true);
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFBC0F", marginTop: 5 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="Logo" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pagesMobile.map(
                (page) =>
                  userState.roles.some((role) =>
                    page.requiredRole?.includes(role)
                  ) && (
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Link
                        key={page.name}
                        underline="none"
                        href={page.url}
                        color="inherit"
                      >
                        <Button
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, display: "block" }}
                        >
                          <Typography textAlign="center">
                            {page.name}
                          </Typography>
                        </Button>
                      </Link>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="Logo" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* Submenu for MEMO HEBDO */}
            <Button
              onClick={handleOpenArticleMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              {t("Memo Hebdo")}
            </Button>
            <Menu
              anchorEl={anchorElArticle}
              open={Boolean(anchorElArticle)}
              onClose={handleCloseArticleMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCloseArticleMenu}>
                <Link underline="none" href="/Articles" color="inherit">
                  {t("Articles")}
                </Link>
              </MenuItem>
              {["ArticleWriter", "ArticleAdmin", "AdminTI"].some((role) =>
                userState.roles.includes(role)
              ) && (
                <MenuItem onClick={handleCloseArticleMenu}>
                  <Link underline="none" href="/WriteArticle" color="inherit">
                    {t("WriteArticle")}
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {/* Submenu for Admin Actions */}
            {["AdminTI", "LocationAdmin", "CompanyAdmin"].some((role) =>
              userState.roles.includes(role)
            ) && (
              <Button
                onClick={handleOpenAdminMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t("Admin")}
              </Button>
            )}
            <Menu
              anchorEl={anchorElAdmin}
              open={Boolean(anchorElAdmin)}
              onClose={handleCloseAdminMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {userState.roles.includes("AdminTI") && (
                <MenuItem onClick={handleCloseAdminMenu}>
                  <Link underline="none" href="/APIKey" color="inherit">
                    {t("APIKey")}
                  </Link>
                </MenuItem>
              )}
              {(userState.roles.includes("AdminTI") ||
                userState.roles.includes("CompanyAdmin")) && (
                <MenuItem onClick={handleCloseAdminMenu}>
                  <Link
                    underline="none"
                    href="/CompanyManagement"
                    color="inherit"
                  >
                    {t("CompanyManagement")}
                  </Link>
                </MenuItem>
              )}
              {(userState.roles.includes("AdminTI") ||
                userState.roles.includes("LocationAdmin")) && (
                <MenuItem onClick={handleCloseAdminMenu}>
                  <Link underline="none" href="/Location" color="inherit">
                    {t("Location")}
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {/*CASSEROLE MENU */}
            {["AdminTI", "CasseroleAdmin"].some((role) =>
              userState.roles.includes(role)
            ) && (
              <Button
                onClick={handleOpenCasseroleMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t("Casserole")}
              </Button>
            )}
            <Menu
              anchorEl={anchorElCasserole}
              open={Boolean(anchorElCasserole)}
              onClose={handleCloseCasseroleMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {["AdminTI", "CasseroleAdmin"].some((role) =>
                userState.roles.includes(role)
              ) && (
                <MenuItem onClick={handleCloseCasseroleMenu}>
                  <Link underline="none" href="/SpecialMessage" color="inherit">
                    {t("SpecialMessage")}
                  </Link>
                </MenuItem>
              )}
              {["AdminTI", "CasseroleAdmin"].some((role) =>
                userState.roles.includes(role)
              ) && (
                <MenuItem onClick={handleCloseCasseroleMenu}>
                  <Link underline="none" href="/Complaint" color="inherit">
                    {t("Complaint")}
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {/*REPORTS MENU */}
            {["AdminTI", "ReportAdmin", "ReportWriter"].some((role) =>
              userState.roles.includes(role)
            ) && (
              <Button
                onClick={handleOpenReportMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t("Reports")}
              </Button>
            )}
            <Menu
              anchorEl={anchorElReport}
              open={Boolean(anchorElReport)}
              onClose={handleCloseReportMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {["AdminTI", "ReportAdmin", "ReportWriter"].some((role) =>
                userState.roles.includes(role)
              ) && (
                <MenuItem onClick={handleCloseReportMenu}>
                  <Link underline="none" href="/Dashboard" color="inherit">
                    {t("Dashboard")}
                  </Link>
                </MenuItem>
              )}
              {["AdminTI", "ReportAdmin", "ReportWriter"].some((role) =>
                userState.roles.includes(role)
              ) && (
                <MenuItem onClick={handleCloseReportMenu}>
                  <Link underline="none" href="/Reports" color="inherit">
                    {t("Reports")}
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {/*SURVEY MENU */}
            {["AdminTI", "InternalEmployee", "Franchisees"].some((role) =>
              userState.roles.includes(role)
            ) && (
              <Button
                onClick={handleOpenSurveyMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t("Surveys")}
              </Button>
            )}
            <Menu
              anchorEl={anchorElSurvey}
              open={Boolean(anchorElSurvey)}
              onClose={handleCloseSurveyMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {(userState.roles.includes("AdminTI") ||
                userState.roles.includes("Franchisees")) && (
                <MenuItem onClick={handleCloseSurveyMenu}>
                  <Link
                    underline="none"
                    href="/CompetitionSurveyFranchisees"
                    color="inherit"
                  >
                    {t("CompetitionSurveyFranchisees")}
                  </Link>
                </MenuItem>
              )}
              {(userState.roles.includes("AdminTI") ||
                userState.roles.includes("InternalEmployee")) && (
                <MenuItem onClick={handleCloseSurveyMenu}>
                  <Link
                    underline="none"
                    href="/CompetitionSurveyInternalEmployees"
                    color="inherit"
                  >
                    {t("CompetitionSurveyInternalEmployees")}
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {pages.map((page) => (
              <Link
                key={page.name}
                underline="none"
                href={page.url}
                color="inherit"
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: location.pathname === page.url ? "black" : "white",
                    backgroundColor:
                      location.pathname === page.url ? "white" : "transparent",
                    display: "block",
                  }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userState.username}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <div key={setting.name}>
                  <MenuItem
                    onClick={(event) =>
                      setting.subMenu
                        ? setting.action(event) // Opens sub-menu for languages
                        : handleMenuAction(setting.action)
                    }
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>

                  {setting.subMenu && (
                    <Menu
                      id={`language-submenu-${index}`}
                      anchorEl={anchorElLangage}
                      open={Boolean(anchorElLangage)}
                      onClose={() => setAnchorElLangage(null)}
                      MenuListProps={{ "aria-labelledby": "language-menu" }}
                    >
                      {setting.subMenu.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onClick={() => subItem.action()}
                        >
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default MenuBar;
