import React, { useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "./css/MFA.css";
import store from "../../../redux/store";
import { login, setLang, setToken } from "../../../redux/actions";
import { useSelector } from "react-redux";

export function MFA(props) {
  const [value, setValue] = useState("email");
  const [secretCode, setSecretCode] = useState();
  const valueRef = useRef("");
  const [isSent, setIsSent] = useState(false);
  const { t, i18n } = useTranslation(["MFA"]);

  const userState = useSelector((state) => state.user);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleValidateCode = () => {
    if (secretCode.trim() === valueRef.current.value.trim()) {
      localStorage.setItem("username", props.userInfo.userName);
      localStorage.setItem("lang", props.userInfo.langue);
      localStorage.setItem(
            "token",
              props.token
            )
     
      store.dispatch(setToken(props.token));
      store.dispatch(
        login({ username: props.userInfo.userName, idSession: props.idSession })
      );
      store.dispatch(setLang(props.userInfo.langue));
      window.location.reload();
    } else {
      alert("code incorrect !");
    }
  };

  const handleSend = async () => {
    var sendMethod = "email";
    var data = props.userInfo.email;
    if (value == "phone") {
      sendMethod = "SMS";
      data = props.userInfo.noTelephoneCellulaire;
    }
    fetch(`${process.env.REACT_APP_API_URL}/login/code${sendMethod}/${data}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
    })
      .then((results) => {
        return results.text();
      })
      .then((data) => {
        setSecretCode(data);
        setIsSent(true);
      });
  };

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">
        {t("WhereToSendCode")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="email"
          control={<Radio />}
          label={props.userInfo.email}
        />
        {props.userInfo.noTelephoneCellulaire && (
          <FormControlLabel
            value="phone"
            control={<Radio />}
            label={props.userInfo.noTelephoneCellulaire}
          />
        )}
      </RadioGroup>
      <Button onClick={handleSend}>Send</Button>
      {isSent && (
        <>
          <TextField
            margin="normal"
            fullWidth
            name="Code"
            label="code"
            type="text"
            id="Code"
            inputRef={valueRef}
          />
          <Button onClick={handleValidateCode}>{t("Validate")}</Button>
        </>
      )}
    </FormControl>
  );
}

export default MFA;
