import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function Profil() {
  const { t, i18n } = useTranslation(["Login"]);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [isInternal, setIsInternal] = useState(true);

  const userState = useSelector((state) => state.user);

  const getUserInfo = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/employee/getEmploye/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setUserInfo(data);
        if (data.email !== "" && data.email !== null) {
          setEmail(data.email);
        }
        if (
          data.noTelephoneCellulaire !== "" &&
          data.noTelephoneCellulaire !== null
        ) {
          setPhoneNumber(data.noTelephoneCellulaire);
        }
      });
  };

  const EmployeIsInterne = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/employee/getIsInternal/${userState.username}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    )
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setIsInternal(data);
      });
  };

  useEffect(() => {
    getUserInfo();
    EmployeIsInterne();
  }, []);

  const handleSave = async () => {
    var dataUser = {
      username: userState.username,
      email: email,
      phoneNumber: phoneNumber,
    };

    fetch(`${process.env.REACT_APP_API_URL}/employee/employe/SaveEmploye`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userState.token}`,
      },
      body: JSON.stringify(dataUser),
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        return <Navigate to="/Memo Hebdo" />;
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#FFBC0F" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("InfoProfil")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label={t("username")}
            name="username"
            disabled={true}
            value={userState.username}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={isInternal}
          />
          <TextField
            margin="normal"
            fullWidth
            name="phoneNumber"
            label={t("phoneNumber")}
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: "#FFBC0F" }}
            onClick={handleSave}
          >
            {t("Save")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Profil;
