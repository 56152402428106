import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./css/Login.css";
import Modal from "@mui/material/Modal";
import MFA from "./MFA/MFA";
import EmailRecuperation from "./MFA/EmailRecuperation";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import ForgotPassword from "./ForgotPassword";
import ForgotUsername from "./ForgotUsername";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

/*
export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()
*/

export function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [userInfo, setUserInfo] = useState();
  const [idSession, setIdSession] = useState();
  const { t, i18n } = useTranslation(["Login"]);
  const [open, setOpen] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openForgotUsername, setOpenForgotUsername] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [checked, setChecked] = useState(false);
  const [ip, setIP] = useState("");
  const [publicIP, setPublicIP] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenForgotPassword = () => setOpenForgotPassword(true);
  const handleOpenForgotUsername = () => setOpenForgotUsername(true);
  const handleCloseForgotPassword = () => setOpenForgotPassword(false);
  const handleCloseForgotUsername = () => setOpenForgotUsername(false);
  const [token, setToken] = useState();

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const getLocalIP = () => {
      return new Promise((resolve, reject) => {
        const RTCPeerConnection =
          window.RTCPeerConnection ||
          window.mozRTCPeerConnection ||
          window.webkitRTCPeerConnection;
        if (!RTCPeerConnection) {
          console.error("WebRTC not supported by this browser.");
          return reject(new Error("WebRTC is not supported by this browser."));
        }

        const rtc = new RTCPeerConnection({
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        });

        rtc.createDataChannel(""); // create a bogus data channel

        rtc.onicecandidate = (event) => {
          if (event && event.candidate && event.candidate.candidate) {
            const candidate = event.candidate.candidate;
            const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
            const ipMatch = ipRegex.exec(candidate);
            if (ipMatch) {
              setIP(ipMatch[1]);
              if (candidate.includes("typ srflx")) {
                setPublicIP(ipMatch[1]);
              } else {
                setIP(ipMatch[1]);
              }
              rtc.close();
            }
          }
        };

        rtc
          .createOffer()
          .then((offer) => rtc.setLocalDescription(offer))
          .catch((err) => {
            console.error("Error creating offer:", err);
            reject(err);
          });
      });
    };

    getLocalIP()
      .then((ip) => {
        console.log("IP:", ip);
      })
      .catch((err) => {
        console.error("Error getting IP:", err);
        //setError(err.message);
      });
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      i18n.changeLanguage("Fr");
    } else {
      i18n.changeLanguage("En");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserName(e.target.username.value);
    setPassword(e.target.password.value);
    const encodedValue = encodeURIComponent(username);

    var dataUser = {
      username: username,
      password: password,
      ipAdresse: ip,
    };
    fetch(`${process.env.REACT_APP_API_URL}/Login/Login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataUser),
    })
      .then((results) => {
        return results.json();
      })
      .then(async (data) => {
        if (data.token) {
          setToken(data.token);
          setIdSession(data.idSession);
          await fetch(
            `${process.env.REACT_APP_API_URL}/employee/GetEmploye/${username}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.token}`,
              },
            }
          )
            .then((results) => {
              return results.json();
            })
            .then((data) => {
              setUserInfo(data);
              if (data.email !== "" && data.email !== null) {
                setHasEmail(true);
              } else {
                setHasEmail(false);
              }
            });
          handleOpen();
        } else {
          alert("username or password incorrect !");
        }
      });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        className="Login__toggleButton"
      >
        <Typography>{t("En")}</Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{t("Fr")}</Typography>
      </Stack>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#FFBC0F" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("SignIn")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label={t("Username")}
              name="username"
              onChange={(e) => setUserName(e.currentTarget.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("RememberMe")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "#FFBC0F" }}
              autoFocus
            >
              {t("SignIn")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" onClick={handleOpenForgotUsername}>
                  {t("ForgotUsername")}
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" onClick={handleOpenForgotPassword}>
                  {t("ForgotPassword")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            {hasEmail ? (
              <MFA userInfo={userInfo} idSession={idSession} token={token} />
            ) : (
              <EmailRecuperation
                username={username}
                idSession={idSession}
                token={token}
              />
            )}
          </Suspense>
        </Box>
      </Modal>

      <Modal
        open={openForgotPassword}
        onClose={handleCloseForgotPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            <ForgotPassword />
          </Suspense>
        </Box>
      </Modal>
      <Modal
        open={openForgotUsername}
        onClose={handleCloseForgotUsername}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Login__Modal">
          <Suspense>
            <ForgotUsername />
          </Suspense>
        </Box>
      </Modal>
    </>
  );
}

export default Login;
