import React, { useEffect, useRef, useState } from "react";
import Editor from "../../Common/Components/Editor/Editor";
import { useSelector } from "react-redux";
import ArticleProperties from "./ArticleProperties";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import "./css/ArticleEditor.css";
import UploadedAttachments from "./UploadedAttachments";

function ArticleEditor({
  article,
  setArticle,
  reloadArticle,
  setReloadArticle,
}) {
  const [textFr, setTextFr] = useState(null);
  const [textEn, setTextEn] = useState(null);
  const [titleFR, setTitleFr] = useState(null);
  const [titleEN, setTitleEn] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [openProperties, setOpenProperties] = useState(false);
  const [lang, setLang] = useState(null);
  const fileInput = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { t, i18n } = useTranslation(["Articles"]);

  const userState = useSelector((state) => state.user);

  const handleClickOpenDialogUpload = () => {
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  useEffect(() => {
    if (article) {
      setTextEn(article.textEn);
      setTextFr(article.textFr);
      setTitleEn(article.titleEn);
      setTitleFr(article.titleFr);
      if (article.employeLock) {
        setReadOnly(true);
      }
    } else {
      setTextEn(null);
      setTextFr(null);
      setTitleEn(null);
      setTitleFr(null);
    }
  }, [article]);

  const handleNewArticle = async (event) => {
    try {
      var data = {
        id: article ? article.id : 0,
        titleFr: "PAS DE TITRE",
        titleEn: "NO TITLE",
        textFr: null,
        textEn: null,
        author: userState.username,
        idEmployeLock: null,
        employeLock: null,
        externalDepartmentId: 0,
      };
      // Send a POST request to save the article
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Article/CreateArticle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(data),
        }
      );

      // Check if the response is OK (status code 2xx)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      // Parse the JSON response
      const result = await response.json();
      console.log(result);
      setArticle(result);

      // Optionally handle the result here (e.g., display a success message)
      console.log("Article saved successfully:", result);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error saving article:", error);
      // Optionally display an error message to the user
    }
  };

  const handleSaveWhenKeyPressed = async (event) => {
    try {
      // Prepare the data to send to the server
      const data = {
        id: article.id,
        titleFr: titleFR,
        titleEn: titleEN,
        textFr: textFr,
        textEn: textEn,
        idEmployeLock: null,
        externalDepartmentId: 0,
      };

      // Send a POST request to save the article
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Article/SaveArticle`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(data),
        }
      );

      // Check if the response is OK (status code 2xx)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      // Parse the JSON response
      const result = await response.json();

      // Optionally handle the result here (e.g., display a success message)
      console.log("Article saved successfully:", result);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error saving article:", error);
      // Optionally display an error message to the user
    }
  };

  const handleUnlockArticle = async (event) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/article/UnlockArticle/${article.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    ).then((results) => {
      return results.json();
    });
  };

  const handleDeleteArticle = async (event) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/DeleteArticle/${article.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the article");
      }

      const result = await response.json();
      setArticle(null);
      // Handle successful deletion (e.g., update the UI or notify the user)
      console.log("Article deleted:", result);

      // Optionally update UI by removing the article from the list
      // removeArticleFromState(article.id);
    } catch (error) {
      console.error("Error deleting the article:", error);
      // Show an error message to the user
    }
  };

  const handleOpenFileDialog = (selectedLang) => {
    setLang(selectedLang);
    fileInput.current.click();
    handleCloseDialogUpload();
  };

  const handleUploadFile = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsFilePicked(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("articleId", article.id);
    formData.append("articleLang", lang);

    // Log FormData contents for debugging
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/article/UploadFile`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  return (
    <>
      <div className="Editor__Main">
        <div className="Editor__ButtonBar">
          <Button onClick={handleNewArticle}>{t("NewArticle")}</Button>
          <Button
            component="label"
            disabled={readOnly}
            onClick={handleClickOpenDialogUpload}
          >
            {t("UploadFile")}
          </Button>
          <Button onClick={handleUnlockArticle}>{t("Unlock")}</Button>
          <Button onClick={handleDeleteArticle}>{t("Delete")}</Button>
          <Button onClick={(e) => setOpenProperties(true)}>
            {t("Properties")}
          </Button>

          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-end"
            className="Editor__ButtonBar__toggleButton"
          >
            <Typography>{t("ReadOnly")}</Typography>
            <Switch
              checked={!readOnly}
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => {
                if (
                  article.employeLock &&
                  article.employeLock.includes(userState.username)
                ) {
                  // If the username is in employeLock, allow toggling read-only
                  setReadOnly(!readOnly);
                } else if (article.employeLock) {
                  // If employeLock exists but the user is not included, show an alert
                  alert(`Article locked by ${article.employeLock}`);
                } else {
                  // If employeLock is null, you might want to toggle the readOnly state
                  setReadOnly(!readOnly);
                }
              }}
            />
            <Typography>{t("EditMode")}</Typography>
          </Stack>
        </div>
        {article && (
          <>
            <div className="ArticleEditorFr">
              <Editor
                title={titleFR}
                setTitle={setTitleFr}
                setText={setTextFr}
                text={textFr}
                readOnly={readOnly}
                onKeyPressed={handleSaveWhenKeyPressed}
                toolbarId={"toolbarFr"}
              />
            </div>
            <div className="ArticleEditorEn">
              <Editor
                title={titleEN}
                setTitle={setTitleEn}
                setText={setTextEn}
                text={textEn}
                readOnly={readOnly}
                onKeyPressed={handleSaveWhenKeyPressed}
                toolbarId={"toolbarEn"}
              />
            </div>
          </>
        )}
      </div>
      {/* DIALOG UPLOAD*/}
      <Dialog
        open={openDialogUpload}
        onClose={handleCloseDialogUpload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Langage ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("WhichLanguage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenFileDialog("FR")}>FR</Button>
          <Button onClick={() => handleOpenFileDialog("EN")}>EN</Button>
          <Button onClick={handleCloseDialogUpload}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("DeleteConfirmationTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("DeleteConfirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus>{t("Yes")}</Button>
          <Button onClick={() => setOpenDialogDelete(false)}>{t("No")}</Button>
        </DialogActions>
      </Dialog>

      <ArticleProperties
        readOnly={readOnly}
        article={article}
        open={openProperties}
        onClose={(e) => setOpenProperties(false)}
        setReloadArticle={setReloadArticle}
      />
      <UploadedAttachments
        article={article}
        readOnly={readOnly}
        realoadArticle={reloadArticle}
      />
      <input
        ref={fileInput}
        type="file"
        style={{ opacity: "0" }}
        onChange={(e) => handleUploadFile(e)}
        onClick={(e) => (e.target.value = "")}
      />
    </>
  );
}

export default ArticleEditor;
