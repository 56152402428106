import { TextField, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function ReportForm({ question, answers, idActivite }) {
  const userState = useSelector((state) => state.user);
  const [listeDeChoix, setListeDeChoix] = useState({});
  const [choices, setChoices] = useState({});
  const [texts, setTexts] = useState({});
  const [commentaireRequiredMap, setCommentaireRequiredMap] = useState({});
  const { t } = useTranslation(["Articles", "Common"]);

  // Fetch dropdown choices based on question group ID
  const fetchChoiceList = async (idGroupeDeChoix) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Report/GetListeDeChoix/${idGroupeDeChoix}`,
      {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userState.token}`,
        },
      }
    );
    const data = await response.json();
    setListeDeChoix((prev) => ({ ...prev, [idGroupeDeChoix]: data }));
  };

  useEffect(() => {
    setTexts({});
    // Fetch dropdown lists for main question and child questions
    if (question && question.idGroupeDeChoix !== 2) {
      fetchChoiceList(question.idGroupeDeChoix);
    }
    question?.childQuestions?.forEach((q) => {
      if (q.idGroupeDeChoix !== 2) {
        fetchChoiceList(q.idGroupeDeChoix);
      }
    });

    // Set initial answers for the main question and child questions
    loadAnswers();
  }, [question, answers, userState.token]);

  const loadAnswers = () => {
    if (!question || !answers || answers.length === 0) return;
    // Load answers for the main question
    setAnswerData(question);

    // Load answers for each child question
    if (question.childQuestions && question.childQuestions.length > 0) {
      question.childQuestions.forEach((childQuestion) => {
        setAnswerData(childQuestion);
      });
    }
  };

  const setAnswerData = (q) => {
    if (!q || !answers) return;
    const matchingAnswer = answers.find((a) => a && a.idQuestion === q.id);
    if (matchingAnswer) {
      setChoices((prev) => ({
        ...prev,
        [q.id]: matchingAnswer.commentaire || "",
      }));
      setTexts((prev) => ({
        ...prev,
        [q.id]: matchingAnswer.commentaire || "",
      }));

      if (listeDeChoix[q.idGroupeDeChoix]) {
        listeDeChoix[q.idGroupeDeChoix].forEach((l) => {
          if (matchingAnswer.idListeDeChoix === l.id) {
            setChoices((prev) => ({
              ...prev,
              [q.id]: userState.lang === "EN" ? l.textEn : l.textFr,
            }));
          }
          if (l.commentaireRequis === true) {
            setCommentaireRequiredMap((prev) => ({ ...prev, [q.id]: true }));
          }
        });
      }
    }
  };

  const handleChoiceChange = (questionId, newValue) => {
    console.log(newValue);
    setChoices((prev) => ({ ...prev, [questionId]: newValue }));
    setTexts((prev) => ({ ...prev, [questionId]: newValue }));
    handleSave(questionId, newValue, texts[questionId]);
  };

  const handleTextChange = (questionId, newText) => {
    console.log("newText");
    setTexts((prev) => ({ ...prev, [questionId]: newText }));
    handleSave(questionId, choices[questionId], newText);
  };

  const handleSave = async (questionId, choix, text) => {
    let idListeDeChoix = 2; // Default choice ID
    if (question.idGroupeDeChoix && question.idGroupeDeChoix !== 2) {
      const filteredChoix = listeDeChoix[question.idGroupeDeChoix]?.filter(
        (ans) =>
          ans.textFr === text ||
          ans.textFr === choix ||
          ans.textEn === text ||
          ans.textEn === choix
      );
      if (filteredChoix && filteredChoix.length > 0) {
        idListeDeChoix = filteredChoix[0].id;
      }
    }

    const answerToSave = {
      IdQuestion: questionId,
      IdActivite: idActivite,
      Reponse: choix || text,
      IdListeDeChoix: idListeDeChoix,
      Username: userState.username,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Report/SaveAnswer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userState.token}`,
          },
          body: JSON.stringify(answerToSave),
        }
      );

      if (!response.ok) {
        alert(`Failed to save answer.`);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Answer saved:", data);
    } catch (error) {
      alert(`Failed to save answer. ${error.message}`);
      console.error("Error saving answer:", error);
    }
  };

  return (
    <>
      {userState.lang === "EN" ? (
        <h4>{question.textEn}</h4>
      ) : (
        <h4>{question.textFr}</h4>
      )}

      {/* Render child questions and main question inputs */}
      {question.childQuestions &&
        question.childQuestions.map((q) => (
          <div key={q.id}>
            <p>{userState.lang === "EN" ? q.textEn : q.textFr}</p>
            <div>
              {q.idGroupeDeChoix === 2 ? (
                <TextField
                  id={`text-${q.id}`}
                  multiline
                  minRows={2}
                  value={texts[q.id] || ""}
                  sx={{ width: "100%" }}
                  onChange={(e) => handleTextChange(q.id, e.target.value)}
                />
              ) : (
                listeDeChoix[q.idGroupeDeChoix] && (
                  <Autocomplete
                    id={`autocomplete-${q.id}`}
                    options={listeDeChoix[q.idGroupeDeChoix].map((choix) =>
                      userState.lang === "EN" ? choix.textEn : choix.textFr
                    )}
                    onChange={(event, newValue) =>
                      handleChoiceChange(q.id, newValue)
                    }
                    value={choices[q.id] || null} // Ensure value is null if not found
                    isOptionEqualToValue={(option, value) =>
                      option === value ||
                      option ===
                        (userState.lang === "EN"
                          ? value?.textEn
                          : value?.textFr)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Answer")} />
                    )}
                  />
                )
              )}
            </div>
          </div>
        ))}

      {/* If there are no child questions, render the main question input */}
      {question.childQuestions?.length === 0 &&
      question.idGroupeDeChoix === 2 ? (
        <TextField
          id={`text-${question.id}`}
          multiline
          minRows={2}
          value={texts[question.id] || ""}
          sx={{ width: "100%", mb: 2 }}
          onChange={(e) => handleTextChange(question.id, e.target.value)}
        />
      ) : (
        listeDeChoix[question.idGroupeDeChoix] && (
          <Autocomplete
            id={`autocomplete-${question.id}`}
            options={listeDeChoix[question.idGroupeDeChoix].map((choix) =>
              userState.lang === "EN" ? choix.textEn : choix.textFr
            )}
            onChange={(event, newValue) =>
              handleChoiceChange(question.id, newValue)
            }
            value={choices[question.id] || null} // Ensure value is null if not found
            isOptionEqualToValue={(option, value) =>
              option === value ||
              option ===
                (userState.lang === "EN" ? value?.textEn : value?.textFr)
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Answer")} />
            )}
          />
        )
      )}

      {commentaireRequiredMap[question.id] && (
        <TextField
          id={`comment-${question.id}`}
          multiline
          value={texts[question.id] || ""}
          onChange={(e) => handleTextChange(question.id, e.currentTarget.value)}
        />
      )}
    </>
  );
}

export default ReportForm;
